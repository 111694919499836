import {
  convertDecimalWithComma,
  dateToDateString,
  ellipsisString
} from "@/app/infrastructures/misc/Utils";
import { HandoverDetail } from "@/domain/entities/Handover";
import jsPDF from "jspdf";
import "jspdf-autotable";

let doc: any;

const drawCellDataConditions = (cell: any): Array<boolean> => [
  cell.column.dataKey === "koli" &&
    (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "grossWeight" &&
    (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "volumeWeight" &&
    (cell.row.section === "head" || cell.row.section === "body"),
  cell.column.dataKey === "product" && cell.row.section === "body",
  cell.column.dataKey === "origin" && cell.row.section === "body",
  cell.column.dataKey === "destination" && cell.row.section === "body"
];
const drawCellData = (cell: any) => {
  const productIdx = 2;
  const totalPiecesIdx = 4;
  const grossWeightIdx = 5;
  const volumeWeightIdx = 6;
  const originIdx = 7;
  const destinationIdx = 8;

  if (drawCellDataConditions(cell)[0]) {
    if (cell.row.section === "head") {
      cell.row.cells[totalPiecesIdx].styles = {
        ...cell.row.cells[totalPiecesIdx].styles,
        cellPadding: {
          ...cell.row.cells[totalPiecesIdx].styles.cellPadding,
          top: 2
        }
      };
    }
    cell.row.cells[totalPiecesIdx].styles = {
      ...cell.row.cells[totalPiecesIdx].styles,
      halign: "center"
    };
  }
  if (drawCellDataConditions(cell)[1]) {
    if (cell.row.section === "head") {
      cell.row.cells[grossWeightIdx].styles = {
        ...cell.row.cells[grossWeightIdx].styles,
        cellPadding: {
          ...cell.row.cells[grossWeightIdx].styles.cellPadding,
          top: 2
        }
      };
    }
    cell.row.cells[grossWeightIdx].styles = {
      ...cell.row.cells[grossWeightIdx].styles,
      halign: "center"
    };
  }
  if (drawCellDataConditions(cell)[2]) {
    if (cell.row.section === "head") {
      cell.row.cells[volumeWeightIdx].styles = {
        ...cell.row.cells[volumeWeightIdx].styles,
        cellPadding: {
          ...cell.row.cells[volumeWeightIdx].styles.cellPadding,
          top: 2
        }
      };
    }
    cell.row.cells[volumeWeightIdx].styles = {
      ...cell.row.cells[volumeWeightIdx].styles,
      halign: "center"
    };
  }
  if (drawCellDataConditions(cell)[3]) {
    doc.setFillColor("#EBECF0");
    cell.row.cells[productIdx].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[productIdx].x + 0.7,
          cell.row.cells[productIdx].y +
            cell.row.cells[productIdx].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells[productIdx].text.filter(
                    (item: any) => item !== ""
                  ).length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[productIdx].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
  if (drawCellDataConditions(cell)[4]) {
    doc.setFillColor("#EBECF0");
    cell.row.cells[originIdx].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[originIdx].x + 0.7,
          cell.row.cells[originIdx].y +
            cell.row.cells[originIdx].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells[originIdx].text.filter(
                    (item: any) => item !== ""
                  ).length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[originIdx].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
  if (drawCellDataConditions(cell)[4]) {
    doc.setFillColor("#EBECF0");
    cell.row.cells[destinationIdx].text
      .filter((item: any) => item !== "")
      .map((_: any, index: number) => {
        doc.roundedRect(
          cell.row.cells[destinationIdx].x + 0.7,
          cell.row.cells[destinationIdx].y +
            cell.row.cells[destinationIdx].contentHeight /
              (1.6333 +
                1.75 *
                  cell.row.cells[destinationIdx].text.filter(
                    (item: any) => item !== ""
                  ).length) +
            (0.0083 + 5.675 * index),
          cell.row.cells[destinationIdx].contentWidth - 1,
          4,
          1,
          1,
          "F"
        );
      });
    doc.setFillColor("#fff");
  }
};

const manifestHandover = async (
  header: HandoverDetail,
  data: any,
  logo: string
) => {
  doc = new jsPDF("p", "mm", "a4", true);
  const pdfWidth = doc.internal.pageSize.width;
  const logoProperties = doc.getImageProperties(logo);
  const logoAspectRatio = logoProperties.height / logoProperties.width;

  const imageWidth = 40;
  const imageHeight = logoAspectRatio * 40;
  doc.addImage(
    logo,
    "PNG",
    pdfWidth - imageWidth - 9,
    11,
    imageWidth,
    imageHeight,
    "logo",
    "FAST",
    0
  );
  doc.setFont("helvetica", "bold");
  doc.setFontSize(16);
  doc.setTextColor("#4d4d4d");
  doc.text("Handover Manifest", 7, 18);
  doc.setFillColor("#e0e0e0");
  doc.rect(7, 25, 195, 0.2, "F");
  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");

  doc.text("Kota Tujuan", 7, 35);
  doc.text(":", 33, 35);
  doc.text(
    `${header.handoverArrivalCitycode} - ${header.handoverArrivalCityName}`,
    35,
    35
  );

  doc.setTextColor("#4d4d4d");
  doc.text("Nama Konsolidator", 7, 40);
  doc.text(":", 33, 40);
  doc.text(`${ellipsisString(header.handoverPartnerName, 65)}`, 35, 40);

  doc.setTextColor("#4d4d4d");
  doc.text("Diserahkan ke", 7, 45);
  doc.text(":", 33, 45);
  doc.text(`${header.handoverDestinationPartnerName}`, 35, 45);

  doc.setTextColor("#4d4d4d");
  doc.text("Tanggal Dibuat", 7, 50);
  doc.text(":", 33, 50);
  doc.text(dateToDateString(header.handoverCreatedAt), 35, 50);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total STT", 110, 35);
  doc.text(":", 135, 35);
  doc.text(`${header.handoverManifestTotalStt}`, 137, 35);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total Koli", 110, 40);
  doc.text(":", 135, 40);
  doc.text(`${header.handoverTotalPiece}`, 137, 40);

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total Berat Kotor", 110, 45);
  doc.text(":", 135, 45);
  doc.text(
    `${convertDecimalWithComma(header.handoverManifestTotalGrossWeight, 2)} Kg`,
    137,
    45
  );

  doc.setFont("helvetica", "normal");
  doc.setFontSize(7);
  doc.setTextColor("#4d4d4d");
  doc.text("Total Berat Dimensi", 110, 50);
  doc.text(":", 135, 50);
  doc.text(
    `${convertDecimalWithComma(
      header.handoverManifestTotalVolumeWeight,
      2
    )} Kg`,
    137,
    50
  );

  let lastPage = 0;
  let lastY = 0;
  let lastHeight = 0;
  const dataLength = data.length;
  doc.autoTable({
    body: data,
    columns: [
      { header: "No.", dataKey: "no" },
      {
        header: `No. STT`,
        dataKey: "sttNo"
      },
      { header: "Produk", dataKey: "product" },
      { header: "Komoditas", dataKey: "commodity" },
      { header: "Total\nKoli", dataKey: "koli" },
      { header: "Berat\nKotor", dataKey: "grossWeight" },
      { header: "Berat\nDimensi", dataKey: "volumeWeight" },
      { header: "Origin", dataKey: "origin" },
      { header: "Dest.", dataKey: "destination" },
      { header: "Catatan", dataKey: "notes" }
    ],
    theme: "plain",
    startY: doc.internal.getCurrentPageInfo().pageNumber === 1 ? 55 : 0,
    margin: { top: 7, left: 7, right: 7, bottom: 7 },
    headStyles: {
      font: "helvetica",
      fontStyle: "bold",
      fillColor: "#f5f6f7",
      textColor: "#1a1421",
      fontSize: 6,
      halign: "left",
      cellPadding: { top: 3, right: 2, bottom: 1.5, left: 2 }
    },
    bodyStyles: {
      font: "helvetica",
      fontStyle: "normal",
      fillColor: "#fff",
      textColor: "#1a1421",
      fontSize: 7,
      halign: "left",
      valign: "top",
      lineColor: "#ffffff",
      lineWidth: { top: 0, right: 0, bottom: 0.2, left: 0 },
      cellPadding: { top: 4, right: 2, bottom: 4, left: 2 },
      cellWidth: "auto"
    },
    columnStyles: {
      3: { cellWidth: 30 },
      9: { cellWidth: 30 }
    },
    didDrawCell: (cell: any) => {
      // top-border
      if (cell.row.section === "body" && cell.row.index > 0) {
        doc.setFillColor("#e0e0e0");
        doc.rect(
          cell.row.cells[0].x - 0.05,
          cell.row.cells[0].y - 0.05,
          195,
          0.2,
          "F"
        );
        doc.setFillColor("#fff");
      }
    },
    willDrawCell: (cell: any) => {
      drawCellData(cell);

      lastPage = cell.pageNumber;
      lastY = cell.cursor.y;
      lastHeight = cell.row.height;
      if (cell.row.index === dataLength - 2 && lastY + lastHeight + 20 > 270) {
        cell.cell.contentHeight = 100;
        cell.row.height = 100;
      }
    }
  });

  lastY = lastY + lastHeight + 20;
  lastY = lastY > 270 ? 270 : lastY;
  doc.setPage(lastPage);
  doc.setFontSize(8);
  doc.text("Station Admin", 89, lastY);
  doc.text("(.................................)", 84, lastY + 20);
  doc.text("Kurir", 135, lastY);
  doc.text("(.................................)", 124, lastY + 20);
  doc.text("Operations Supervisor", 164, lastY);
  doc.text("(.................................)", 164, lastY + 20);
  doc.setProperties({
    title: "Handover Manifest"
  });
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

export default manifestHandover;
