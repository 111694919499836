
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import {
  convertDecimalWithComma,
  dateToDateString,
  dateToTime
} from "@/app/infrastructures/misc/Utils";
import Title from "@/app/ui/components/data-wrapper/title.vue";
import { HandoverController } from "@/app/ui/controllers/HandoverController";
import { AccountController } from "@/app/ui/controllers/AccountController";

@Options({
  components: {
    DetailLayout,
    Title
  }
})
export default class Detail extends Vue {
  mounted() {
    this.getDetailHandover(this.$route.params.id);
  }

  getDetailHandover(id: any) {
    HandoverController.getDetailHandover(id);
  }

  get partnerType() {
    const accountType = AccountController.accountData.account_type;
    const accountTypeDetail =
      AccountController.accountData.account_type_detail.type;
    const isConsole =
      accountTypeDetail === "console" ? "Consolidator" : "Sub Consolidator";
    return (accountType === "partner" ? isConsole : accountType) || "internal";
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  get title(): string {
    return `Handover Manifest - ${this.id}`;
  }

  get detailData() {
    return HandoverController.detailHandover;
  }

  get isLoading() {
    return HandoverController.isLoadingDetail;
  }

  get isError() {
    return HandoverController.isError;
  }
  get errorCause() {
    return HandoverController.errorCause;
  }

  // table
  get columns() {
    return [
      {
        name: "No.",
        styleHead: "w-5 text-left whitespace-nowrap",
        render: (_: any, i: number) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${i +
            1}</span>`
      },
      {
        name: "No. STT",
        styleHead: "w-56 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 whitespace-nowrap">
                  ${item.sttNo}
                </div>`;
        }
      },
      {
        name: "Produk",
        styleHead: "w-56 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 flex">
                  <div class="rounded px-2 py-0 bg-gray-lp-400">
                      ${item.sttProductType}
                  </div>
                </div>`;
        }
      },
      {
        name: "Commodity Name",
        styleHead: "w-64 text-left whitespace-nowrap",
        render: (item: any) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${item.sttCommodityName}</span>`
      },
      {
        name: "Total Koli",
        styleHead: "w-48 text-left whitespace-nowrap",
        styleCustom: "justify-center",
        render: (item: any) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${item.sttTotalPiece}</span>`
      },
      {
        name: "Gross Weight",
        styleHead: "w-64 text-left whitespace-nowrap",
        render: (item: any) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${convertDecimalWithComma(
            item.sttGrossWeight,
            2
          )} Kg</span>`
      },
      {
        name: "Volume Weight",
        styleHead: "w-64 text-left whitespace-nowrap",
        render: (item: any) =>
          `<span class="font-normal text-black-lp-300 whitespace-nowrap">${convertDecimalWithComma(
            item.sttVolumeWeight,
            2
          )} Kg</span>`
      },
      {
        name: "Origin",
        styleHead: "w-56 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 whitespace-nowrap">
                  ${item.sttOriginCityId} - ${item.sttOriginCityName}
                </div>`;
        }
      },
      {
        name: "Dest.",
        styleHead: "w-56 text-left whitespace-nowrap",
        render: (item: any) => {
          return `<div class="text-black-lp-300 whitespace-nowrap">
                  ${item.sttDestinationCityId} - ${item.sttDestinationCityName}
                </div>`;
        }
      }
    ];
  }

  pagination = {
    page: 1,
    limit: 100
  };

  goBack() {
    this.$router.push("/handover");
  }

  formatDate(date: string) {
    return `${dateToDateString(date)}, ${dateToTime(date)} WIB`;
  }

  convertDecimalWithComma(value: any) {
    return convertDecimalWithComma(value, 2);
  }

  // Print

  async print(type: string) {
    const book: any = await import("../modules/print.vue");
    const printType: any = {
      summary: () => book.default.methods.printSummary(this.roleAccount),
      basic: () => book.default.methods.print(this.roleAccount)
    };

    printType[type]();
  }

  // role account users
  get roleAccount() {
    return (
      (AccountController.accountData.account_type === "partner"
        ? AccountController.accountData.account_type_detail.type
        : AccountController.accountData.account_type) || "internal"
    );
  }
}
