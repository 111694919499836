
import {
  convertDecimalWithComma,
  dateToDateString
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { HandoverController } from "@/app/ui/controllers/HandoverController";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { HandoverDetail, HandoverSttResult } from "@/domain/entities/Handover";
import { Vue } from "vue-class-component";
import generateHandoverManifest from "./manifest-handover";
import generateSummaryPdf from "@/app/ui/modules/summary";

export default class Print extends Vue {
  async print(accountType: string, id = 0) {
    MainAppController.showLoading();
    // logo
    const logo = await import(`@/app/ui/assets/images/logo-${accountType}.png`);
    const printManifest =
      !id || (await HandoverController.getDetailHandover(id));

    if (printManifest) {
      const detailHandover = HandoverController.detailHandover;

      const data = detailHandover.stt.map(
        (item: HandoverSttResult, index: number) => ({
          no: index + 1,
          sttNo: item.sttNo,
          product: item.sttProductType,
          commodity: item.sttCommodityName,
          koli: item.sttTotalPiece,
          grossWeight: convertDecimalWithComma(item.sttGrossWeight, 2),
          volumeWeight: convertDecimalWithComma(item.sttVolumeWeight, 2),
          origin: item.sttOriginCityId,
          destination: item.sttDestinationCityId,
          notes: ""
        })
      );
      await generateHandoverManifest(detailHandover, data, logo.default);
    }
    MainAppController.closeLoading();
  }

  async printSummary(accountType: string, id = 0) {
    MainAppController.showLoading();
    // logo
    const logo = await import(`@/app/ui/assets/images/logo-${accountType}.png`);
    const printManifest =
      !id || (await HandoverController.getDetailHandover(id));
    if (printManifest) {
      const detail: HandoverDetail = HandoverController.detailHandover;

      const data: Array<any> = [
        {
          name: "Kota Tujuan",
          value: `${detail.handoverArrivalCitycode} - ${detail.handoverArrivalCityName}`
        },
        {
          name: "Nama Consolidator",
          value: AccountController.accountData.name
        },
        {
          name: "Diserahkan Ke",
          value: detail.handoverDestinationPartnerName
        },
        {
          name: "Tanggal Handover",
          value: dateToDateString(detail.handoverCreatedAt)
        },
        {
          name: "Total STT",
          value: detail.handoverManifestTotalStt
        },
        {
          name: "Total Koli",
          value: detail.handoverTotalPiece
        },
        {
          name: "Total Berat Kotor",
          value: `${convertDecimalWithComma(
            detail.handoverManifestTotalGrossWeight,
            2
          )} Kg`
        },
        {
          name: "Total Berat Dimensi",
          value: `${convertDecimalWithComma(
            detail.handoverManifestTotalVolumeWeight,
            2
          )} Kg`
        }
      ];

      await generateSummaryPdf(
        `Handover Manifest - ${detail.handoverManifestId}`,
        data,
        logo.default
      );
    }
    MainAppController.closeLoading();
  }
}
