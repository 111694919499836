import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pt-8 bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "flex z-20" }
const _hoisted_3 = { class: "font-semibold self-center mr-10 text-20px whitespace-no-wrap text-black-lp-200 capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_Search = _resolveComponent("Search")!
  const _component_Select = _resolveComponent("Select")!
  const _component_time_range_picker = _resolveComponent("time-range-picker")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_Header = _resolveComponent("Header")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Breadcrumb),
    _createVNode(_component_Header, { class: "items-center" }, {
      default: _withCtx(() => [
        _createVNode("div", _hoisted_2, [
          _createVNode("div", _hoisted_3, _toDisplayString(_ctx.getTitle), 1),
          _createVNode(_component_Search, {
            class: "w-48 self-center",
            onInput: _ctx.onSearch,
            onClear: _ctx.clearSearch,
            value: _ctx.searchValue,
            minChar: 1,
            timeout: 1000,
            placeholder: "Cari ID Manifest"
          }, null, 8, ["onInput", "onClear", "value"]),
          _createVNode(_component_Select, {
            dataSelect: _ctx.subConsoleList,
            onChooseSelect: _ctx.onSelectSubConsole,
            onOpenSelect: _ctx.onOpenSelectSubConsole,
            onCloseSelect: _ctx.onCloseSelectSubConsole,
            isOpenMenu: _ctx.isOpenSelectSubConsole,
            value: _ctx.destinationPartnerId,
            placeholder: "Pilih Sub Consolidator",
            class: "mr-4"
          }, null, 8, ["dataSelect", "onChooseSelect", "onOpenSelect", "onCloseSelect", "isOpenMenu", "value"]),
          _createVNode(_component_time_range_picker, {
            default: "30 hari terakhir",
            startDate: _ctx.startDate,
            endDate: _ctx.endDate,
            onSelect: _ctx.setDateRange
          }, null, 8, ["startDate", "endDate", "onSelect"])
        ]),
        (_ctx.ableToCreate)
          ? (_openBlock(), _createBlock(_component_lp_button, {
              key: 0,
              title: "Update Handover",
              textColor: "white",
              onClick: _ctx.goToUpdate
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_TableV2, {
      columns: _ctx.columns,
      data: _ctx.handoverList,
      loading: _ctx.isLoading,
      pagination: _ctx.pagination,
      "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
      totalColumnFreeze: 1,
      isRightFreeze: true,
      onRequest: _ctx.fetchHandoverList,
      isDetailAble: _ctx.ableToDetail,
      onClick: _ctx.onClickRow,
      errorCause: _ctx.errorCause,
      borderTop: false,
      onTryAgain: _ctx.fetchHandoverList
    }, null, 8, ["columns", "data", "loading", "pagination", "onRequest", "isDetailAble", "onClick", "errorCause", "onTryAgain"])
  ]))
}